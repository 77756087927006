export const uniqBy = <T>(arr: T[], key: keyof T): T[] => {
    return Object.values(
      arr.reduce(
        (map, item) => ({
          ...map,
          [`${item[key]}`]: item,
        }),
        {},
      ),
    )
  }
  
export const uniqValues = (value: string, index: number, self: string[]) => {
  return self.indexOf(value) === index
}

export const dateToUnix = (_date?: Date) => {
  const date = _date || new Date()

  return Math.floor(date.getTime() / 1000)
}